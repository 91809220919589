/*スクロールダウン全体の場所*/
.scrolldown1 {
  /*描画位置※位置は適宜調整してください*/
  position: absolute;
  left: 50%;
  bottom: 10px;
  /*全体の高さ*/
  height: 250px;
}

/*Scrollテキストの描写*/
.scrolldown1 span {
  /*描画位置*/
  position: absolute;
  left: -15px;
  top: -15px;
  /*テキストの形状*/
  color: #000;
  font-size: 0.7rem;
  letter-spacing: 0.05em;
}

/* 線の描写 */
.scrolldown1::after {
  content: '';
  /*描画位置*/
  position: absolute;
  top: 0;
  /*線の形状*/
  width: 1px;
  height: 300px;
  background: #000;
  /*線の動き1.4秒かけて動く。永遠にループ*/
  animation: pathmove 1.4s ease-in-out infinite;
  opacity: 0;
}

/*高さ・位置・透過が変化して線が上から下に動く*/
@keyframes pathmove {
  0% {
    height: 0;
    top: 0;
    opacity: 0;
  }
  30% {
    height: 30px;
    opacity: 1;
  }
  100% {
    height: 0;
    top: 50px;
    opacity: 0;
  }
}
